"use client";
import Link from "next/link";

// Error components must be Client Components

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <div
      data-testid="error"
      className="flex items-center h-full p-16 bg-white rounded-2xl"
    >
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 font-extrabold text-9xl text-indigo-500">
            <span className="sr-only">Error</span>Oops
          </h2>
          <p className="text-2xl font-semibold md:text-3xl text-black">
            Sorry, an error has occurred
          </p>
          <p className="mt-4 mb-8 text-gray-500">
            But don&apos;t worry, you can try again or refresh the page.
            Otherwise, please{" "}
            <Link
              href={"/contact"}
              className="font-semibold text-black underline"
            >
              contact support.
            </Link>
          </p>

          <button
            onClick={() => reset()}
            className="mt-4 px-8 py-2 rounded-md bg-indigo-500 hover:bg-indigo-400 text-white font-semibold transition-colors duration-200 ease-in-out"
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
}
